import React from "react"; 
import { Link } from "react-router-dom"; 
import './navigationBar.css';

function Navbar() {   
  return (     
    <nav className="nav">       
      <ul className="navList">         
        <li className="navListPoints">           
          <Link role="button" className="navLinks home" to="/">Home</Link>         
        </li>         
        <li className="navListPoints">           
          <Link role="button" className="navLinks echos" to="/echos">Echos</Link>         
        </li>         
        <li className="navListPoints">           
          <Link role="button" className="navLinks contact" to="/contact">Contact</Link>         
        </li>       
      </ul>     
    </nav>
  ); 
} 
export default Navbar; 