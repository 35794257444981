const echoJson = require('../../../../EchoDetailedData.json');

function EchoSearchSelector({setSelectedEcho}) {
  return (
    <div className="listOfConfirmedEchos">
      <label htmlFor="selectedEchoChoice" className="selectedEchoInfo">Search an Echo</label>
      <input list="selectedEcho" name="selectedEchoChoice" id="selectedEchoChoice" placeholder="Double-click for options"/>
      <datalist id="selectedEcho">
        {
          echoJson.map((currEntry, index) => {
            return (
              <option key={index} className="confirmedEchoOptions" value={currEntry.name}>{currEntry.name}</option>
            )
          })
        }
      </datalist>
      <button className="searchButton" onClick={() => {
        if (document.getElementById("selectedEchoChoice").value) {
          Object.values(document.getElementsByClassName("confirmedEchoOptions")).forEach((confirmedEchoEntry) => {
            if (document.getElementById("selectedEchoChoice").value === confirmedEchoEntry.value) {
              setSelectedEcho(document.getElementById("selectedEchoChoice").value);
        }})}}}>Confirm</button>
    </div>
  )
}
export default EchoSearchSelector;