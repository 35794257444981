import React from 'react'; 
function Home() {
  return (
    <>
      <h1>Homepage</h1>
      <br></br>
      <p>Send feedback to my Discord: static.elements</p>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <p>WIP Page</p>
    </>
    
  ); 
} 
export default Home;