import React from 'react'; 
function Contacts() {
  return (
    <>
      <h1>Contact Me</h1>
      <h3>Discord: static.elements</h3>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <p>WIP Page</p>
    </>
  ); 
} 
export default Contacts;