import React from "react";
const {globals} = require("../../../../globalVariables");

function DetailedTableView({selectedEcho}) {
  const [echosInfoData, setEchosInfoData] = React.useState([]);
  const [hasRetrieved, setHasRetrieved] = React.useState("false");

  React.useEffect(() => {
    setHasRetrieved("false");
    fetch(globals.urlAPI + "/api/echos_details/" + selectedEcho)
      .then((response) => {
        return response.json();
      })
      .then((newEchosInfo) => {
        setEchosInfoData(newEchosInfo);
        setHasRetrieved("true");
      }).catch((error) => {
        console.error(`Error getting echos_details: ${error.message}`);
      });
  }, [selectedEcho]);

  if (hasRetrieved === "true") {
    return (
      <div className="detailedViewTableDiv">
        <div className="DetailedViewTop">
          <div>Name: {echosInfoData[0].name}</div>
          <div>Slot: {echosInfoData[0].echoSlot}</div>
          <div>Level: {echosInfoData[0].level}</div>
        </div>
        <table id="detailedechosTable">
          <thead className="echosTableHeader">
            <tr className="echosTableHeaderRow">
              <th className="echosTableHeaderLabels DetailedTier">Tier</th>
              <th className="echosTableHeaderLabels DetailedRank">Rank</th>
              <th className="echosTableHeaderLabels DetailedCapacity">Capacity</th>
              <th className="echosTableHeaderLabels DetailedSelected">Selected</th>
              <th className="echosTableHeaderLabels DetailedOther">Other</th>
            </tr>
          </thead>
          <tbody>
            {echosInfoData.map((echoData, index) => (
              <React.Fragment key={3000000 + index}>
                <tr>
                  <td>{echoData.rarity}</td>
                  <td>{echoData.rank}</td>
                  <td>{echoData.capacity}</td>
                  <td>{echoData.attribute1}</td>
                  <td>{echoData.attribute2}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}
export default DetailedTableView;