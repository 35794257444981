function AttributeFiltering ({setFilteredAttribute}){

  const resetCurrButtonBackgrounds = () => {
    const sortButton = document.getElementsByClassName("filterButtons");
    for(let currValue of sortButton){
      currValue.className = "Special-Buttons filterButtons";
    };
  }
  const changeCurrButtonBackgrounds = (currentSortButton) => {
    const currentButton = document.getElementById(currentSortButton);
    currentButton.className = "SelectedButtons Special-Buttons filterButtons";
  }

  const echoAttributes = [
    "No Filter",
    "Max Health", 
    "Resilience",
    "Weapon Power",
    "Ability Power",
    "Crit Rating",
    "Crit Power",
    "Break Power",
    "Physical Defense",
    "Magical Defense"
  ];

  return(
    <div className="Echos-filtering-Buttons-Container">
        <div className="FilterAttributes">
          <div className="Header">Filter by Attribute</div>
          {echoAttributes.map((curButton, id) => {
            return <button key={id} id={curButton} className={
              curButton === "No Filter" 
              ? "Special-Buttons filterButtons SelectedButtons" 
              : "Special-Buttons filterButtons"
            } onClick={() => {
              resetCurrButtonBackgrounds();
              changeCurrButtonBackgrounds(curButton);
              setFilteredAttribute(curButton);
            }}>{curButton}</button>
          })}
        </div>
      </div>
  )
}
export default AttributeFiltering;