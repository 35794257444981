import React from 'react';
import '../index.css';
import EchosSearchingApp from '../components/echos-page/EchosSearchingApp';

function Echos() {
  return (
    <>
      <EchosSearchingApp />
    </>
  ); 
}
export default Echos;