import React from "react";

function ColumnSortingDefaultView({setOrderedBy}) {
  const [prevButtonBackend, setPrevButtonBackend] = React.useState("echos.name");

  const resetCurrButtonBackgrounds = () => {
    const sortButton = document.getElementsByClassName("sortButtons");
    for(let currValue of sortButton){
      currValue.className = "Special-Buttons sortButtons";
    };
  }
  const changeCurrButtonBackgrounds = (currentSortButton) => {
    const currentButton = document.getElementById(currentSortButton);
    currentButton.className = "SelectedButtons Special-Buttons sortButtons";
  }

  const resetCurrAscDescButtonsBackgrounds = () => {
    const sortButton = document.getElementsByClassName("ascDescButtons");
    for(let currValue of sortButton){
      currValue.className = "Special-Buttons ascDescButtons";
    };
  }
  const changeCurrAscDescButtonsBackgrounds = (currentSortButton) => {
    const currentButton = document.getElementById(currentSortButton);
    currentButton.className = "SelectedButtons Special-Buttons ascDescButtons";
  }
  const resetCurrAscDescButtonsDefaultBackgrounds = () => {
    const currentButton = document.getElementById("Ascending");
    currentButton.className = "SelectedButtons Special-Buttons ascDescButtons";
  }

  const sortOptions = [
    "Name",
    "Slot",
    "Ascending",
    "Descending"
  ];

  return (
    <div className="Echos-Sorting-Buttons-Container">
      <div className="SortStuff">
        <div className="Header">Sort by Column</div>
        {sortOptions.map((curButton, index) => {
          let curButtonBackend;
          switch(curButton){
            case "Name":
              curButtonBackend = "echos.name"
              break;
            case "Slot": 
              curButtonBackend = "echoSlot"
              break;
            case "Ascending":
              curButtonBackend = prevButtonBackend + " ASC"
              break;
            case "Descending":
              curButtonBackend = prevButtonBackend + " DESC"
              break;
            default:
              curButtonBackend = "No Sort"
              break;
          };

          return <button key={1000 + index} id={curButton} className={
            curButton === "Name" 
            ? "Special-Buttons sortButtons SelectedButtons" 
            : curButton === "Ascending"
              ? "Special-Buttons ascDescButtons SelectedButtons" 
              : curButton === "Descending"
                ? "Special-Buttons ascDescButtons"
                : "Special-Buttons sortButtons"} 
          onClick={() => {

            if (curButton === "Ascending" || curButton === "Descending") {
              setOrderedBy(curButtonBackend);
              resetCurrAscDescButtonsBackgrounds();
              changeCurrAscDescButtonsBackgrounds(curButton);
            } else {
              setPrevButtonBackend(curButtonBackend);
              setOrderedBy(curButtonBackend);
              resetCurrButtonBackgrounds();
              changeCurrButtonBackgrounds(curButton);
              resetCurrAscDescButtonsBackgrounds();
              resetCurrAscDescButtonsDefaultBackgrounds();
            }
          }}>{curButton}</button>
        })}
      </div>
    </div>
  )
}
export default ColumnSortingDefaultView;