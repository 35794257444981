import "./App.css";
import React from "react";
import AttributeFiltering from "./EchoAppComponents/AttributeFiltering";
import DefaultTableView from "./EchoAppComponents/DefaultView/DefaultTableView";
import ColumnSortingDefaultView from "./EchoAppComponents/DefaultView/ColumnSortingDefaultView";
import InformationTableView from "./EchoAppComponents/InformationView/InformationTableView";
import ColumnSortingInformationView from "./EchoAppComponents/InformationView/ColumnSortingInformationView";
import EchoSearchSelector from "./EchoAppComponents/DetailedView/EchoSearchSelector";
import DetailedTableView from "./EchoAppComponents/DetailedView/DetailedTableView";

function EchosSearchingApp() {
  const [filteredAttribute, setFilteredAttribute] = React.useState("No Filter");
  const [echosSelectedView, setEchosSeletedView] = React.useState("Default");
  const [orderedBy, setOrderedBy] = React.useState("No Sort");
  const [selectedEcho, setSelectedEcho] = React.useState("Archon Commander Echo")
  
  const renderDefaultView = () => {
    return (
      <>
        <div className="buttonsss">
          <ColumnSortingDefaultView setOrderedBy={setOrderedBy} /> 
          <AttributeFiltering setFilteredAttribute={setFilteredAttribute} />
        </div>
        <DefaultTableView filteredAttribute={filteredAttribute} orderedBy={orderedBy} />
      </>
    )
  }
  const renderInformationView = () => {
    return (
      <> 
        <div className="buttonsss">
          <ColumnSortingInformationView setOrderedBy={setOrderedBy} />
          <AttributeFiltering setFilteredAttribute={setFilteredAttribute} />
        </div>
        <InformationTableView filteredAttribute={filteredAttribute} orderedBy={orderedBy} />
      </>
    )
  }
  const renderDetailedView = () => {
    return (
      <>
        <div className="buttonsss">
          <EchoSearchSelector setSelectedEcho={setSelectedEcho}/>
        </div>
        <DetailedTableView selectedEcho={selectedEcho}/>
      </>
    )
  }

  return (
    <div className="Echos-App">
      <div className="View-Selector-Flexbox">
        <button className="View-Buttons" onClick={() => {setEchosSeletedView("Default"); setOrderedBy("No Sort"); setFilteredAttribute("No Filter");}}>Default View</button>
        <button className="View-Buttons" onClick={() => {setEchosSeletedView("Information"); setOrderedBy("No Sort"); setFilteredAttribute("No Filter");}}>Information View</button>
        <button className="View-Buttons" onClick={() => {setEchosSeletedView("Detailed"); setOrderedBy("No Sort"); setFilteredAttribute("No Filter");}}>Detailed View</button>
      </div>
      <div className="Echos-Table">
        {echosSelectedView !== "Information"
          ? echosSelectedView !== "Detailed"
            ? renderDefaultView()
            : renderDetailedView()
          : renderInformationView() 
        }
      </div>
    </div>
  )
}
export default EchosSearchingApp;