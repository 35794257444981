import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import Layout from "./pages/Layout"; 
import Home from "./pages/Home"; 
import Echos from "./pages/Echos"; 
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";

export default function App() {   
  return (     
   <BrowserRouter>       
    <Routes>         
      <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />   
      <Route path="echos/*" element={<Echos />} />       
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<NoPage />} />
      </Route>       
    </Routes>     
  </BrowserRouter>   
  ); 
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
