// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav{
  height: 4vw;
  background-color: #6b432e;
}
.navList {
  list-style-type:none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  height: 100%;
}
.navListPoints{
  width: 25%;
  text-align: center;
  display: flex;
  justify-content: center;
}
.navLinks{
  line-height: 4vw;
  font-size: 2vw;
  width: 100%;
}
.nav a {
  text-decoration: none;
}
.nav a {color: white;}

.home:hover, .echos:hover, .contact:hover {background-color: #8f5a3d;}`, "",{"version":3,"sources":["webpack://./src/navigationBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,oBAAoB;EACpB,aAAa;EACb,iBAAiB;EACjB,8BAA8B;EAC9B,UAAU;EACV,SAAS;EACT,YAAY;AACd;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,WAAW;AACb;AACA;EACE,qBAAqB;AACvB;AACA,QAAQ,YAAY,CAAC;;AAErB,2CAA2C,yBAAyB,CAAC","sourcesContent":[".nav{\n  height: 4vw;\n  background-color: #6b432e;\n}\n.navList {\n  list-style-type:none;\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0;\n  height: 100%;\n}\n.navListPoints{\n  width: 25%;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n}\n.navLinks{\n  line-height: 4vw;\n  font-size: 2vw;\n  width: 100%;\n}\n.nav a {\n  text-decoration: none;\n}\n.nav a {color: white;}\n\n.home:hover, .echos:hover, .contact:hover {background-color: #8f5a3d;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
