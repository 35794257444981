import React from "react";
const {globals} = require("../../../../globalVariables");

function InformationTableView({filteredAttribute, orderedBy}) {
  const [echosInfoData, setEchosInfoData] = React.useState([]);

  React.useEffect(() => {
    fetch(globals.urlAPI + "/api/echos_Info/" + filteredAttribute + "/" + orderedBy)
      .then((response) => {
        return response.json();
      })
      .then((newEchosInfo) => {
        setEchosInfoData(newEchosInfo);
      }).catch((error) => {
        console.error(`Error getting echos_info: ${error.message}`)
      });
  }, [filteredAttribute, orderedBy]);

  return (
    <table id="echosTable">
        <thead className="echosTableHeader">
          <tr className="echosTableHeaderRow">
            <th className="echosTableHeaderLabels Name">Name</th>
            <th className="echosTableHeaderLabels EchoSlot">Slot</th>
            <th className="echosTableHeaderLabels Capacity">Level</th>
            <th className="echosTableHeaderLabels Capacity">Capacity</th>
            <th className="echosTableHeaderLabels Selected">Selected</th>
            <th className="echosTableHeaderLabels Other">Other</th>
          </tr>
        </thead>
        <tbody>
          {echosInfoData.map((echoData, index) => (
            <React.Fragment key={2000000 + index}>
              <tr>
                <td>{echoData.name}</td>
                <td>{echoData.echoSlot}</td>
                <td>{echoData.level}</td>
                <td>{echoData.capacity}</td>
                <td>{echoData.selected}</td>
                <td>{echoData.other}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
  )
}
export default InformationTableView;